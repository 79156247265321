module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139040956-1"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"ld1y35y2","include_in_development":true,"delay_timeout":0},
    }]
